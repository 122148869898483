<template>
  <div style="background: #F6F6F6; height: 100%;">
    <div 
      style="display: flex; 
      justify-content: center; 
      position: relative;
      background: #fff;
      padding: 7.5px 2.6%;"
    >
      <van-icon name="arrow-left" size="22" style="position: absolute; left: 2%; top: 50%; transform: translateY(-50%);"/>
      <div style="background: #F1F1F1; display: flex; border-radius: 12px;">
        <van-button 
          round size="mini"
          :color="background == 1 ? 'linear-gradient(to right, #FF7200, #FF3C00)' : '#F1F1F1' "
          @click="orderTitle(1)"
        >
          <span :style="{color: background == 1 ? '#fff' :'#333', margin: '0 11px'}">我的订单</span>
        </van-button>
        <van-button 
          round size="mini"
          :color="background == 2 ? 'linear-gradient(to right, #FF7200, #FF3C00)' : '#F1F1F1' "
          @click="orderTitle(2)"
        >
          <span :style="{color: background == 2? '#fff' : '#333', margin: '0 11px'}">粉丝订单</span>
        </van-button>
      </div>
    </div>

    <div class="Ge-van-tab--active">
     <div
      @click="selectOrder(index)"
      v-for="(item, index) in orderType"
      :key="index"
     >
       <div :style="{ color: color == index ? '#FF5200' : '#333' }">{{item}}</div>
       <div 
        :style="{width: '8px', height: '1px',
          border: color == index ? '1px solid #FF5200' : '1px solid transparent',
          borderRadius: '2px',
          margin: '7px auto 0',
          background: color == index ? '#FF5200' : 'transparent'}"
        ></div>
     </div>
    </div>

    <div class="Ge-prder" style="width: 96%; margin: 10px 2%; 0">
      <div 
        style="background: #fff; 
        padding: 0 2%;
        margin-bottom: 10px;
        border-radius: 10px;"
        v-for="(item, index) in shops"
        :key="index"
      >
        <div style="font-size: 14px; display: flex; justify-content: space-between; padding: 12px 0 18px;">
          <span style="color: #333;">香婆婆小碗菜</span>
          <span style="color: #FF5200;">{{ 
            item.orderStatus == 2 ? '出餐中' :
            item.orderStatus == 3 ? '已出餐' :
            item.orderStatus == 4 ? '骑手配送中' :
            item.orderStatus == 8 ? '用户取消待用户处理' : ''
          }}</span>
        </div>
        <div style="display: flex; overflow: auto;" id="GeScroll">
          <div 
            style="display: flex; 
            flex-direction: column; 
            width: 86px; 
            margin-right: 10px; 
            flex-shrink: 0;"
            v-for="(item2, index2) in item.takeoutMyOrderGoodsVOList"
            :key="index2"
          >
            <img style="width: 85px; height: 50px;" :src="item2.images" alt="">
            <span style="color: #999; font-size: 12px;">{{ item2.goodsName }}</span>
          </div>
        </div>

        <div 
          style="font-size: 14px; color: #333; 
          display: flex; 
          justify-content: space-between;
          margin-top: 8px;
          align-items: center;"
        >
          <span>下单时间：{{ item.createTime }}</span>
          <span>
            <span>合计：</span>
            <span style="color: #A80000;">
              <span>￥</span>
              <span style="font-size: 18px;">{{ item.priceGoods }}</span>
            </span>
          </span>
        </div>

        <div style="padding: 10px 0 16px; display: flex; justify-content: space-between;">
          <div></div>
          <van-button 
            round 
            type="info" 
            size="mini" 
            color="linear-gradient(to right, #FF7200, #FF3C00)"
            @click="$router.push({
              path: '/evaluateShop',
              query: {
                id: item.id
              }
            })"
          >
            <span style="padding: 0 11px;">去评价</span>
          </van-button>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { getOrder } from '@/api/Ge'
  export default {
    data () {
      return {
        background: 1,
        active: 1,
        orderType: ['全部', '待付款', '待发货', '待收货', '待评价', '待使用', '退款/售后' ],
        color: 0,
        state: 0,
      shops: [{takeoutMyOrderGoodsVOList: [{},{}], id: '33'},{}]
      }
    },
    created () {
      this.getOrder()
      this.$store.commit('setSession', this.$route.query.session)
    },
    methods: {
      orderTitle (num) {
        this.background = num
      },

      selectOrder (index) {
        this.color = index
      },

      getOrder () {
        getOrder('state=' + this.state).then(res => {
          console.log(res)
          if (res.data.code === 0) {
            // this.shops = res.data.data.records
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
.Ge-van-tab--active {
  display: flex;
  justify-content: space-evenly;
  background: white;
  font-size: 14px;
  padding: 13px 0;
}
/deep/ .Ge-van-tab--active .van-tab--active {
  color: #FF5200;
}
/deep/ .Ge-van-tab--active .van-tabs__line {
  background-color: #FF5200;
  width: 20px;
}
#GeScroll::-webkit-scrollbar {
  display: none;
}
</style>