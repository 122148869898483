var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"background":"#F6F6F6","height":"100%"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","position":"relative","background":"#fff","padding":"7.5px 2.6%"}},[_c('van-icon',{staticStyle:{"position":"absolute","left":"2%","top":"50%","transform":"translateY(-50%)"},attrs:{"name":"arrow-left","size":"22"}}),_c('div',{staticStyle:{"background":"#F1F1F1","display":"flex","border-radius":"12px"}},[_c('van-button',{attrs:{"round":"","size":"mini","color":_vm.background == 1 ? 'linear-gradient(to right, #FF7200, #FF3C00)' : '#F1F1F1'},on:{"click":function($event){return _vm.orderTitle(1)}}},[_c('span',{style:({color: _vm.background == 1 ? '#fff' :'#333', margin: '0 11px'})},[_vm._v("我的订单")])]),_c('van-button',{attrs:{"round":"","size":"mini","color":_vm.background == 2 ? 'linear-gradient(to right, #FF7200, #FF3C00)' : '#F1F1F1'},on:{"click":function($event){return _vm.orderTitle(2)}}},[_c('span',{style:({color: _vm.background == 2? '#fff' : '#333', margin: '0 11px'})},[_vm._v("粉丝订单")])])],1)],1),_c('div',{staticClass:"Ge-van-tab--active"},_vm._l((_vm.orderType),function(item,index){return _c('div',{key:index,on:{"click":function($event){return _vm.selectOrder(index)}}},[_c('div',{style:({ color: _vm.color == index ? '#FF5200' : '#333' })},[_vm._v(_vm._s(item))]),_c('div',{style:({width: '8px', height: '1px',
        border: _vm.color == index ? '1px solid #FF5200' : '1px solid transparent',
        borderRadius: '2px',
        margin: '7px auto 0',
        background: _vm.color == index ? '#FF5200' : 'transparent'})})])}),0),_c('div',{staticClass:"Ge-prder",staticStyle:{"width":"96%","margin":"10px 2%"}},_vm._l((_vm.shops),function(item,index){return _c('div',{key:index,staticStyle:{"background":"#fff","padding":"0 2%","margin-bottom":"10px","border-radius":"10px"}},[_c('div',{staticStyle:{"font-size":"14px","display":"flex","justify-content":"space-between","padding":"12px 0 18px"}},[_c('span',{staticStyle:{"color":"#333"}},[_vm._v("香婆婆小碗菜")]),_c('span',{staticStyle:{"color":"#FF5200"}},[_vm._v(_vm._s(item.orderStatus == 2 ? '出餐中' : item.orderStatus == 3 ? '已出餐' : item.orderStatus == 4 ? '骑手配送中' : item.orderStatus == 8 ? '用户取消待用户处理' : ''))])]),_c('div',{staticStyle:{"display":"flex","overflow":"auto"},attrs:{"id":"GeScroll"}},_vm._l((item.takeoutMyOrderGoodsVOList),function(item2,index2){return _c('div',{key:index2,staticStyle:{"display":"flex","flex-direction":"column","width":"86px","margin-right":"10px","flex-shrink":"0"}},[_c('img',{staticStyle:{"width":"85px","height":"50px"},attrs:{"src":item2.images,"alt":""}}),_c('span',{staticStyle:{"color":"#999","font-size":"12px"}},[_vm._v(_vm._s(item2.goodsName))])])}),0),_c('div',{staticStyle:{"font-size":"14px","color":"#333","display":"flex","justify-content":"space-between","margin-top":"8px","align-items":"center"}},[_c('span',[_vm._v("下单时间："+_vm._s(item.createTime))]),_c('span',[_c('span',[_vm._v("合计：")]),_c('span',{staticStyle:{"color":"#A80000"}},[_c('span',[_vm._v("￥")]),_c('span',{staticStyle:{"font-size":"18px"}},[_vm._v(_vm._s(item.priceGoods))])])])]),_c('div',{staticStyle:{"padding":"10px 0 16px","display":"flex","justify-content":"space-between"}},[_c('div'),_c('van-button',{attrs:{"round":"","type":"info","size":"mini","color":"linear-gradient(to right, #FF7200, #FF3C00)"},on:{"click":function($event){return _vm.$router.push({
            path: '/evaluateShop',
            query: {
              id: item.id
            }
          })}}},[_c('span',{staticStyle:{"padding":"0 11px"}},[_vm._v("去评价")])])],1)])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }